<template>
  <div>
    <AuthLayout>
      <div class="mb-2">
        <img src="../../../assets/icons/padlock-icon.svg" alt="padlock-icon" />
      </div>
      <h1 class="mb-2">Forgot Password</h1>

      <span>
        Enter the email associated with your account and we will send an email
        with instructions to reset your password.
      </span>

      <ValidationObserver v-slot="{ invalid }">
        <div class="my-3">
          <Input
            type="email"
            placeholder="Enter your Email Address"
            v-model="formData.email"
            name="Email"
            rules="required|email"
          />
        </div>

        <Button
          @click="forgotPassword"
          :disabled="invalid || loading"
          :loading="loading"
          class="mb-2"
          theme="blue"
        >
          Send your instructions
        </Button>
      </ValidationObserver>

      <router-link
        tag="div"
        class="text__mid-grey flex flex__center cursor-pointer"
        :to="{ name: 'login' }"
      >
        <img src="../../../assets/icons/back-icon.svg" alt="back-icon" />
        &nbsp;
        <span class="text-blue">Back to login page</span>
      </router-link>
    </AuthLayout>
    <NewModal v-model="showModal" target="modal-portal">
      <div class="flex flex__center">
        <div class="mb-3">
          <img
            src="../../../assets/icons/success-icon.svg"
            alt="success-icon"
          />
        </div>
      </div>

      <div class="text-center mb-2">
        <h2 class="mb-1">Reset Password</h2>
        <span class="text-center text__light-grey">
          Please check your inbox and use the instructions in the email to reset
          your password. Be patient, this may take few minutes.
        </span>
      </div>
      <p class="text-center">
        Haven't received yet?
        <button @click="resendEmail" class="cursor-pointer">
          <span class="text-green">Resend email</span>
        </button>
      </p>
    </NewModal>
  </div>
</template>

<script>
import AuthLayout from "../../../layouts/AuthLayout";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { mapActions } from "vuex";
import authService from "../../../services/api/authService";

export default {
  name: "ForgotPassword",
  components: {
    AuthLayout,
    Input,
    Button,
    Modal
  },
  data() {
    return {
      formData: {
        email: ""
      },
      showModal: false,
      loading: false
    };
  },
  methods: {
    ...mapActions({
      forgotUserPassword: "auth/forgotPassword"
    }),
    forgotPassword() {
      const payload = {
        ...this.formData,
        redirectUrl: `${window.location.origin}/job-seeker/reset-password`
      };

      this.loading = true;
      authService
        .requestResetPassword(payload)
        .then((res) => {
          this.$handleSuccess(res);
          this.showModal = !this.showModal;
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resendEmail() {
      this.forgotPassword();
      this.showModal = !this.showModal;
    }
  },
  watch: {
    showModal: function (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.$router.push("/job-seeker/login");
      }
    }
  }
};
</script>

<style scoped></style>
